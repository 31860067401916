@import url('https://fonts.googleapis.com/css2?family=Beiruti:wght@200..900&family=Exo+2:ital,wght@0,100..900;1,100..900&family=Parkinsans:wght@300..800&display=swap');


/* :root {
    --notepad-font-family: 'Exo 2', 'Proxima Nova', sans-serif;
    --root-font-family: 'Exo 2', 'Proxima Nova', sans-serif;
    --line-height: 1.5 */

/* font-size: 0.8em;
} */

/* Light Theme */
.light-theme {
    --notepad-title-color: #563727;
    --contact-form-background-color: #f0f0f0;
    --contact-form-field-background-color: #e0e0e0;
    --webpage-background-color: #e6e3e3;
    --notepad-background-color: hsl(0, 0%, 94%);
    --box-shadow-color: #b38952;
    --box-border-color: #b38952;
    --text-color: #333333;
    --icon-color: #333333;
    --button-arrow-color: #80461B;
    --notepad-math-physics-background-color: #f0f0f0;
    --semesters-button-color: #80461B;
    --title-box-border-color: #563727;
    --content-box-border-color: #999999;
    --plot-grades-box-color: #b38952;
    --contact-form-button-color: #80461B;
    --navbar-background-color: #ffffff;
    --navbar-text-color: #333333;
    --course-details-background-color: #f0f0f0;
    --course-details-text-color: #333333;
    --course-details-table-color: #333333;
    --course-table-border-color: #cccccc;
    --footer-background-color: #cfcece;
    --a-text-color: #80461B;
    --invert-filter: invert(0%);
    --invert-filter-for-black: invert(100%);
    --button-hover-color: #555;
    --invert-filter-for-white: invert(6%);
    --invert-filter-for-gray: invert(10%);
    --contact-me-button-background-color: #80461B;
    --theme-button-background-color: #b38952;

    --path-DLA: url('../assets/gifs/WB_DLA_Particle_Sim.gif');
    --path-Random: url('../assets/gifs/WB_Random_Particle_Sim.gif');
}

/* Dark Theme */
.dark-theme {
    --notepad-title-color: hwb(0 83% 17%);
    --contact-form-background-color: #2c2c2c;
    --contact-form-field-background-color: #3b3b3b;
    --webpage-background-color: #1c1c1c;
    --notepad-background-color: hsl(0, 0%, 17%);
    --box-shadow-color: #555555;
    --box-border-color: #555555;
    --text-color: #e0e0e0;
    --icon-color: #e0e0e0;
    --button-arrow-color: #ff6347;
    --notepad-math-physics-background-color: #2c2c2c;
    --semesters-button-color: #ff6347;
    --title-box-border-color: #d3d3d3;
    --content-box-border-color: #777777;
    --plot-grades-box-color: #555555;
    --contact-form-button-color: #ff6347;
    --navbar-background-color: #333333;
    --navbar-text-color: #e0e0e0;
    --course-details-background-color: #2c2c2c;
    --course-details-text-color: #e0e0e0;
    --course-details-table-color: #e0e0e0;
    --course-table-border-color: #444444;
    --footer-background-color: #343333;
    --a-text-color: #ff6347;
    --invert-filter: invert(100%);
    --invert-filter-for-white: invert(83%);
    --invert-filter-for-gray: invert(10%);
    --invert-filter-for-black: invert(6%);
    --button-hover-color: #555;
    --contact-me-button-background-color: #ff6347;
    --theme-button-background-color: #ff6347;

    --path-DLA: url('../assets/gifs/BB_DLA_Particle_Sim.gif');
    --path-Random: url('../assets/gifs/BB_Random_Particle_Sim.gif');

}

.desktop {
    --line-height: 1.5;
    --notepad-font-family: 'Exo 2', 'Proxima Nova', sans-serif;
    --root-font-family: 'Exo 2', 'Proxima Nova', sans-serif;
    --margin-notepad-button: 5px;
    --notepad-width: 60%;
    --width-notepad-math-physics: 92%;
    --Heading-max-width: 55%;
    --right-column-worknotepad-font-size: 1.15em;
    --particle-simulation-font-size: 1.6em;
    --width-notepad-content-style: 90%;
    --width-plot-grades-container: 90%;
    --Heading-margin-bottom: 5vh;
    --Heading-quote-font-size: 1.8em;
    --Heading-author-font-size: 1.6em;
    --button-container-after-margin-left-or-right: 40%;
    --Heading-scroll-down-button-margin-bottom: 20vh;
    --theme-switcher-right: 1%;
    --notepad-title-margin-top-and-bottom: 3%;
    --font-size-plot-grades-text: 0.65em;
    --translate-button-right: 20%;
    --translate-button-left: -20%;
    --width-image-company: 17%;
    --main-text-font-size: 0.8em;
    --font-size-outside-notepad-box: 1.2em;
    --font-size-keywords: 1.1em;
    --position-image-company: center;
    --work-notepad-content-width: 90%;

}


.mobile {
    --line-height: 1.4;
    --notepad-font-family: 'Beiruti', sans-serif;
    --root-font-family: 'Beiruti', sans-serif;
    --margin-notepad-button: 5%;
    --width-notepad-math-physics: 90%;
    --notepad-width: 90%;
    --Heading-max-width: 80%;
    --right-column-worknotepad-font-size: 0.95em;
    --width-notepad-content-style: 85%;
    --width-plot-grades-container: 90%;
    --Heading-margin-bottom: 5vh;
    --Heading-quote-font-size: 1.6em;
    --Heading-author-font-size: 1.2em;
    --button-container-after-margin-left-or-right: 0%;
    --Heading-scroll-down-button-margin-bottom: 20vh;
    --theme-switcher-right: 3%;
    --notepad-title-margin-top-and-bottom: 6%;
    --particle-simulation-font-size: 0.9em;
    --font-size-plot-grades-text: 0.6em;
    --translate-button-left: -70%;
    --translate-button-right: 70%;
    --width-image-company: 22%;
    --main-text-font-size: 1em;
    --font-size-outside-notepad-box: 1em;
    --font-size-keywords: 0.9em;
    --position-image-company: left;
    --work-notepad-content-width: 100%;
}

body {
    background-color: var(--webpage-background-color);
    /* background-image: url('../../src/assets/images/backgroundImage.png');
    background-size: cover;
    background-repeat: no-repeat; */
    color: var(--text-color);
    font-family: var(--root-font-family);
}

.header-background {
    /* background-image: url('../../src/assets/images/backgroundImage.png'); */
    background-size: cover;
    background-repeat: no-repeat;
}