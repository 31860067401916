.work-notepad-content {
    margin: 0px;
    line-height: var(--line-height);
}

.left-column {
    text-align: var(--position-image-company);
    align-items: left;
}


.right-column {
    font-size: var(--right-column-worknotepad-font-size);
    width: 100%;
    /* Add padding to the left of the right column */
    text-align: left;
    font-size: var(--right-column-worknotepad-font-size)
}


.image-company {
    /* width: var(--width-image-company); */
    max-width: var(--width-image-company);
    box-shadow: 2px 2px 2px var(--box-border-color);
    padding: 2%;
    border-radius: 10px;

}

.content-about-experience {
    line-height: var(--line-height);
    font-size: var(--font-size-outside-notepad-box);
    font-family: var(--root-font-family);
    line-height: 1.6;
    color: var(--text-color);
}

.separation {
    border-top: 3px dashed var(--box-border-color);
}

.skill-set-and-description {

    /* Adjust the gap between columns */
    /* Add padding to the whole content area */
    background-color: var(--notepad-background-color);
    /* Relative positioning for the flip */
    font-family: var(--notepad-font-family);
    /* Use your desired font family */
    /* Darker border for content */
    border-radius: 10px;
    font-size: 0.6em;
    /* Larger font size */
    color: var(--text-color);
    /* Lighter text color */
    /* Justify text */
    /* Equal width to the container */
    /* text-align: left; */
}

.keywords {
    margin: 2%;
    font-size: var(--font-size-keywords);
}

.work-notepad-content {
    display: flex;
    flex-direction: column;
    width: var(--work-notepad-content-width);
    /* border-collapse: collapse; */
}

.invertInDarkMode {
    filter: var(--invert-filter);
}

.workpad-container {
    padding: 2%;
}